import {
  Button,
  Dialog,
  FormControl,
  InputLabel,
  Stack,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useState } from "react";
import { ReactComponent as SendWhiteIcon } from "../../../assets/sidebar/contact-icon.svg";
import { ReactComponent as SendIcon } from "../../../assets/trial/send.svg";
import {
  CustomDialogActions,
  CustomDialogContent,
  CustomDialogTitle,
} from "../../../components/CustomDialog";
import axios from "axios";
import { DOMAIN } from "../../../utils/config";
import { getAuthorization } from "../../../utils/helpers";
import { useDispatch } from "react-redux";
import { setNotify } from "../../../redux/utils/utilsSlice";

export const CustomTextField = styled(TextField)(({ theme }) => {
  const { palette } = useTheme();

  return {
    marginBottom: "20px",
    "label + &": {
      marginTop: theme.spacing(2),
    },
    "& .MuiInputBase-input": {
      borderBottom: "0px",
      fontSize: 16,
      padding: "10px 12px",
      backgroundColor: palette.neutral[100],
      border: `2px solid ${palette.neutral[400]}`,
      borderRadius: "6px",
      "&:focus": {
        borderColor: palette.primary[600],
        boxShadow: `0 0 0 2px #0C6FE280`,
      },
    },
    "& .MuiInputBase-root.MuiFilledInput-root": {
      padding: 0,
    },
    overflow: "hidden",
    borderRadius: "4px",
  };
});

export const CustomInputLabel = ({ label, required }) => {
  const { palette } = useTheme();

  return (
    <InputLabel
      variant="standard"
      style={{
        marginTop: "-30px",
        width: "100%",
      }}
    >
      <Typography
        variant="c16px500"
        style={{
          color: palette.neutral[500],
        }}
      >
        {label}{" "}
        {required && <span style={{ color: palette.neutral[500] }}>*</span>}
      </Typography>
    </InputLabel>
  );
};

const CustomSendIcon = styled(SendIcon)(({ theme, isDisabled }) => {
  const { palette } = useTheme();
  return {
    color: isDisabled ? theme.palette.action.disabled : palette.neutral[500],
    opacity: isDisabled ? 0.5 : 1,
  };
});

const CustomWhiteSendIcon = styled(SendWhiteIcon)(({ theme, isDisabled }) => {
  const { palette } = useTheme();

  return {
    color: isDisabled ? theme.palette.action.disabled : palette.neutral[100],
    opacity: isDisabled ? 0.5 : 1,
  };
});
export default function SendNotification({ subjectId, enabled }) {
  const { palette } = useTheme();

  const [open, setOpen] = useState(false);

  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");

  const [loading, setLoading] = useState(false);

  const [titleError, setTitleError] = useState(null);

  const dispatch = useDispatch();

  const handleSendNotification = async () => {
    setTitleError(null);
    setLoading(true);

    if (!title) {
      setTitleError("Title is required");
      setLoading(false);
      return;
    }

    if (title.length <= 5) {
      setTitleError("Title is too short");
      setLoading(false);
      return;
    }

    axios({
      method: "POST",
      url: `${DOMAIN}/trials/subjects/notify/`,
      params: {
        subject_id: subjectId,
      },
      data: {
        title,
        description: message,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: getAuthorization(),
      },
    })
      .then((res) => {
        setTitle("");
        setMessage("");
        setOpen(false);
        setLoading(false);
        dispatch(
          setNotify({
            open: true,
            action: "Notification send successfully",
            severity: "info",
            autoHideDuration: 3000,
            vertical: "bottom",
            horizontal: "right",
          })
        );
      })
      .catch((err) => {
        setLoading(false);
        dispatch(
          setNotify({
            open: true,
            action: "Something went wrong",
            severity: "info",
            autoHideDuration: 3000,
            vertical: "bottom",
            horizontal: "right",
          })
        );
      });
  };

  return (
    <>
      <Button
        disabled={!enabled}
        sx={{
          fontSize: "16px",
          fontWeight: "500",
          color: palette.neutral[500],
          borderRadius: "6px",
          padding: "8px",
        }}
        startIcon={<CustomSendIcon isDisabled={!enabled} />}
        onClick={() => setOpen(true)}
        data-cy="notify__button"
      >
        Notify
      </Button>
      <Dialog open={open} onClose={() => setOpen(false)} fullWidth>
        <CustomDialogTitle
          data-cy="title__dialog"
          onClose={() => setOpen(false)}
        >
          Send Notification
        </CustomDialogTitle>
        <CustomDialogContent>
          <Stack spacing={1.5} mb={4}>
            <Typography
              variant="c16px500"
              sx={{
                color: palette.neutral[500],
              }}
            >
              To:
            </Typography>
            <Typography
              variant="c16px600"
              sx={{
                color: palette.neutral[700],
              }}
            >
              Subject ID: {subjectId}
            </Typography>
          </Stack>

          <FormControl fullWidth>
            <CustomInputLabel label="Title" required />
            <CustomTextField
              data-cy="title__input"
              fullWidth
              error={titleError ? true : false}
              helperText={titleError}
              variant="filled"
              placeholder="Please enter a title for the message..."
              type="text"
              InputProps={{ disableUnderline: true }}
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              required
            />
          </FormControl>
          <FormControl fullWidth>
            <CustomInputLabel label="Message" />
            <CustomTextField
              data-cy="message__input"
              multiline
              rows={4}
              fullWidth
              variant="filled"
              placeholder="Please enter a message for the subject..."
              type="text"
              InputProps={{ disableUnderline: true }}
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              required
            />
          </FormControl>
        </CustomDialogContent>
        <CustomDialogActions>
          <Button
            data-cy="send__button"
            type="submit"
            disabled={loading}
            sx={{
              fontSize: "16px",
              fontWeight: "500",
              color: palette.neutral[100],
              borderRadius: "6px",
              backgroundColor: palette.primary[500],
              padding: "10px 16px",
              lineHeight: "19.36px",
              "&:hover": {
                color: palette.neutral[100],
                backgroundColor: palette.primary[500],
              },
            }}
            endIcon={<CustomWhiteSendIcon isDisabled={loading} />}
            onClick={handleSendNotification}
          >
            {loading ? "Sending" : "Send"}
          </Button>
        </CustomDialogActions>
      </Dialog>
    </>
  );
}
