import { useDispatch } from "react-redux";
import { setActiveTab } from "../../../redux/utils/utilsSlice";

import { Button, Grid, Stack, Typography, useTheme } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import Loading from "../../../components/Blockers/Loading";
import { DOMAIN } from "../../../utils/config";
import { getAuthorization } from "../../../utils/helpers";
import AddTrial from "./AddTrial";
import { ReactComponent as BarChartIcon } from "../../../assets/trial/bar-chart.svg";
import { toCamelCase } from "../../../utils/camelCase";

function Trials() {
  const [loading, setLoading] = useState(true);
  const [trials, setTrials] = useState([]);

  const userId = JSON.parse(localStorage.getItem("user")).id;
  console.log(userId);

  // react-router-dom navigator
  const navigator = useNavigate();

  // redux
  const dispatch = useDispatch();

  const { palette } = useTheme();

  useEffect(() => {
    dispatch(setActiveTab("trials"));
  }, [dispatch]);

  useEffect(() => {
    setLoading(true);
    axios({
      method: "GET",
      url: `${DOMAIN}/trials/`,
      headers: {
        Authorization: getAuthorization(),
      },
    })
      .then((res) => {
        setTrials(res.data.payload);
        console.log(res.data.payload);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const Card = (props) => (
    <Grid
      item
      xs
      sx={{
        minWidth: 356,
        maxWidth: 640,
      }}
    >
      <Stack
        data-cy={`${toCamelCase(props.name)}`}
        direction="column"
        sx={{
          cursor: "pointer",
          height: "100%",
          borderRadius: "6px",
          border: `1px solid ${palette.neutral[300]}`,
          "&:hover": {
            border: `1px solid ${palette.primary[500]}`,
          },
          "&:clicked": {
            border: `1.5px solid ${palette.primary[500]}`,
          },
          backgroundColor: palette.neutral[100],
        }}
        onClick={() => {
          navigator(`trial/${props.id}`);
        }}
      >
        <Stack direction="column" spacing={2} p="24px 24px 16px" height="180px">
          <Stack height="48px">
            <Typography
              style={{
                fontSize: "20px",
                fontWeight: "600",
                textAlign: "left",
                lineHeight: "normal",
                color: palette.neutral[900],
                display: "-webkit-box",
                overflow: "hidden",
                WebkitBoxOrient: "vertical",
                WebkitLineClamp: 2,
                textOverflow: "ellipsis",
              }}
            >
              {props.name}
            </Typography>
          </Stack>
          <Stack height="76px">
            <Typography
              variant="body1"
              style={{
                textAlign: "left",
                color: palette.neutral[500],
                display: "-webkit-box",
                overflow: "hidden",
                WebkitBoxOrient: "vertical",
                WebkitLineClamp: 4,
                textOverflow: "ellipsis",
              }}
            >
              {props.description}
            </Typography>
          </Stack>
        </Stack>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          padding="12px 24px"
          height="60px"
          sx={{
            borderTop: `1px solid ${palette.neutral[300]}`,
          }}
        >
          <Typography
            variant="c14px500"
            style={{
              textAlign: "left",
              color: palette.primary[500],
              backgroundColor: palette.primary[100],
              padding: "4px 8px",
              borderRadius: "4px",
            }}
          >
            {userId === props.partner ? "Owner" : "Collaborator"}
          </Typography>
          <Button
            endIcon={<BarChartIcon fontSize="16px" />}
            onClick={(e) => {
              e.stopPropagation(); // Stop event propagation here
              navigator(`/trial/${props.id}/overview`);
            }}
            sx={{
              color: palette.neutral[500],
              fontSize: "16px",
              fontWeight: 500,
              "&:hover": {
                backgroundColor: palette.neutral[200],
              },
              "&:clicked": {
                backgroundColor: palette.neutral[300],
              },
            }}
          >
            Trial Overview
          </Button>
        </Stack>
      </Stack>
    </Grid>
  );

  return (
    <Grid
      container
      style={{
        height: "100%",
        minHeight: "100vh",
      }}
    >
      <Loading loading={loading}>
        {trials.length ? (
          <>
            <Grid item xs={12}>
              <Stack
                direction="row"
                justifyContent="space-between"
                spacing={2}
                alignItems="center"
                px={{ xs: "32px", ll: "48px" }}
                py="32px"
                // component={Paper}
                style={{
                  position: "sticky",
                  top: "0",
                  backgroundColor: palette.neutral[100],
                  zIndex: 101,
                  width: "100%",
                  boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.07)",
                }}
              >
                <Typography
                  variant="c24px600"
                  style={{
                    textAlign: "left",
                  }}
                >
                  All Trials
                </Typography>
                <Stack
                  direction="row"
                  justifyContent="flex-end"
                  alignItems="center"
                >
                  <AddTrial />
                </Stack>
              </Stack>

              <Grid
                container
                px={{ xs: "32px", xl: "48px" }}
                py={3}
                sx={{
                  backgroundColor: "transparent !important",
                  zIndex: 100,
                  display: "grid",
                  gridTemplateColumns: "repeat(auto-fit, minmax(356px, 1fr))",
                }}
                spacing={3}
              >
                {trials.map((item, index) => (
                  <Card key={index} {...item} />
                ))}
              </Grid>
            </Grid>
          </>
        ) : (
          <AddTrial isNew={true} />
        )}
      </Loading>
    </Grid>
  );
}

export default Trials;
