const { createTheme } = require("@mui/material");

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lt: 1024, // laptop
      lg: 1200,
      ll: 1440, // large laptop
      xl: 1536, // extra large
      xxl: 1748,
      dt: 1920, // desktop
    },
  },
  palette: {
    primary: {
      100: "#F2F4F7",
      200: "#E7F2FF",
      300: "#7FBAFF",
      500: "#2D81E3",
      600: "#0C6FE2",
      700: "#0651A9",
      900: "#00162F",
      main: "#2d81e3",
    },
    neutral: {
      100: "#FFFFFF",
      200: "#f7f7f7",
      300: "#e8e8e8",
      400: "#BCBCBC",
      500: "#7e7e7e",
      700: "#4a4a4a",
      900: "#2d2d2d",
      main: "#ffffff",
      dark: "#000000",
    },
    error: {
      main: "#BD5250",
    },
    success: {
      main: "#00993E",
    },
  },
  typography: {
    fontFamily: "Inter, sans-serif",
    fontSize: {
      xs: "0.75rem",
      sm: "0.875rem",
      md: "1rem",
      lg: "1.125rem",
      xl: "1.25rem",
      xxl: "1.5rem",
    },
    fontWeightLight: "300",
    fontWeightRegular: "400",
    fontWeightMedium: "500",
    fontWeightBold: "600",
    c32px500: {
      fontSize: "32px",
      fontWeight: 500,
      lineHeight: "29px",
      letterSpacing: "0em",
    },
    c28px500: {
      fontSize: "28px",
      fontWeight: 500,
      lineHeight: "34px",
      letterSpacing: "0em",
    },
    c24px500: {
      fontSize: "24px",
      fontWeight: 500,
      lineHeight: "29px",
      letterSpacing: "0em",
    },
    c20px500: {
      fontSize: "20px",
      fontWeight: 500,
      lineHeight: "24px",
      letterSpacing: "0em",
    },
    c18px500: {
      fontSize: "18px",
      fontWeight: 500,
      lineHeight: "22px",
      letterSpacing: "0em",
    },
    c16px500: {
      fontSize: "16px",
      fontWeight: 500,
      lineHeight: "19px",
      letterSpacing: "0em",
    },
    c14px500: {
      fontSize: "14px",
      fontWeight: 500,
      lineHeight: "17px",
      letterSpacing: "0em",
    },
    c12px500: {
      fontSize: "12px",
      fontWeight: 500,
      lineHeight: "15px",
      letterSpacing: "0em",
    },
    c32px600: {
      fontSize: "32px",
      fontWeight: 600,
      lineHeight: "39px",
      letterSpacing: "0em",
    },
    c28px600: {
      fontSize: "28px",
      fontWeight: 600,
      lineHeight: "34px",
      letterSpacing: "0em",
    },
    c24px600: {
      fontSize: "24px",
      fontWeight: 600,
      lineHeight: "29px",
      letterSpacing: "0em",
    },
    c20px600: {
      fontSize: "20px",
      fontWeight: 600,
      lineHeight: "24px",
      letterSpacing: "0em",
    },
    c18px600: {
      fontSize: "18px",
      fontWeight: 600,
      lineHeight: "22px",
      letterSpacing: "0em",
    },
    c16px600: {
      fontSize: "16px",
      fontWeight: 600,
      lineHeight: "19px",
      letterSpacing: "0em",
    },
    c14px600: {
      fontSize: "14px",
      fontWeight: 600,
      lineHeight: "17px",
      letterSpacing: "0em",
    },
    c12px600: {
      fontSize: "12px",
      fontWeight: 600,
      lineHeight: "15px",
      letterSpacing: "0em",
    },
    c24px400: {
      fontSize: "24px",
      fontWeight: 400,
      lineHeight: "29px",
      letterSpacing: "0em",
    },
    c20px400: {
      fontSize: "20px",
      fontWeight: 400,
      lineHeight: "24px",
      letterSpacing: "0em",
    },
    c18px400: {
      fontSize: "18px",
      fontWeight: 400,
      lineHeight: "22px",
      letterSpacing: "0em",
    },
    c16px400: {
      fontSize: "16px",
      fontWeight: 400,
      lineHeight: "19px",
      letterSpacing: "0em",
    },
    c14px400: {
      fontSize: "14px",
      fontWeight: 400,
      lineHeight: "17px",
      letterSpacing: "0em",
    },
    c12px400: {
      fontSize: "12px",
      fontWeight: 400,
      lineHeight: "15px",
      letterSpacing: "0em",
    },
    body1: {
      fontSize: "16px",
      fontWeight: 400,
      lineHeight: "normal",
      letterSpacing: "0em",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        // common for all MuiButton
        root: {
          borderRadius: "6px",
          gap: "6px",
          fontSize: "16px",
          fontWeight: "500",
          lineHeight: "19px",
          letterSpacing: "0em",
          boxShadow: "none",
        },
        // contained
        contained: {},
        containedSizeSmall: {
          padding: "8px, 12px, 8px, 12px",
        },
        containedSizeMedium: {
          padding: "10px, 16px, 10px, 16px",
        },
        containedSizeLarge: {
          padding: "12px, 16px, 12px, 16px",
        },

        containedPrimary: {
          backgroundColor: "#2D81E3", // Default background color
          "&:hover": {
            backgroundColor: "#0C6FE2", // Hover background color
          },
          "&:active": {
            backgroundColor: "#0651A9", // Clicked background color
          },
          "&:disabled": {
            backgroundColor: "rgba(45, 129, 227, 0.5)", // Disabled background color
          },
          color: "#FFFFFF",
        },

        // outlined
        outlined: {},
        outlinedSizeSmall: {
          padding: "8px, 12px, 8px, 12px",
        },
        outlinedSizeMedium: {
          padding: "10px, 16px, 10px, 16px",
        },
        outlinedSizeLarge: {
          padding: "12px, 16px, 12px, 16px",
        },
        outlinedPrimary: {
          backgroundColor: "#FFFFFF", // Default background color
          border: "1px solid #7E7E7E",
          color: "#7E7E7E",

          "&:hover": {
            backgroundColor: "#F7F7F7", // Hover background color
            border: "1px solid #7E7E7E",
            color: "#7E7E7E",
          },
          "&:active": {
            backgroundColor: "#E8E8E8", // Clicked background color
            border: "1px solid #4A4A4A",
            color: "#7E7E7E",
          },
          "&:disabled": {
            backgroundColor: "rgba(255, 255, 255, 0.5)",
            border: "1px solid rgba(126, 126, 126, 0.5)", // Disabled background color
            color: "rgba(126, 126, 126, 0.5)",
          },
        },
        // text
        text: {},
        textSizeSmall: {
          padding: "0px",
        },
        textSizeMedium: {
          padding: "8px",
        },
        textSizeLarge: {},
        textPrimary: {
          color: "#7E7E7E",

          "&:hover": {
            backgroundColor: "#F7F7F7", // Hover background color
            color: "#7E7E7E",
          },
          "&:active": {
            backgroundColor: "#E8E8E8", // Clicked background color
            color: "#7E7E7E",
          },
          "&:disabled": {
            backgroundColor: "rgba(255, 255, 255, 0.5)",
            color: "rgba(126, 126, 126, 0.5)",
          },
        },
        textSecondary: {
          color: "#7E7E7E",

          "&:hover": {
            color: "#4A4A4A",
            textDecoration: "underline",
          },
          "&:active": {
            color: "#4A4A4A",
            textDecoration: "underline",
          },
          "&:disabled": {
            color: "rgba(126, 126, 126, 0.7)",
          },
        },
      },
    },
  },
  spacing: 8,
});

export default theme;
